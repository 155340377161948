<template>
  <div class="login_container">
    <ForgetPwdInner></ForgetPwdInner>
  </div>
</template>
<script>
import { translateTitle } from "@/assets/js/i18n";
import { mapActions, mapGetters } from "vuex";
import ForgetPwdInner from "@/pages/login/forgetPwd-inner.vue";
export default {
  mixins: [],

  data() {
    return {};
  },
  components: { ForgetPwdInner },
  computed: {},
  created() {},
  beforeDestroy() {},
  destroyed() {},
  mounted() {},
  methods: {
    translateTitle,
  },
};
</script>
<style lang="scss"></style>
